<template>
  <div class="disclosure">
    <h1>Volvo DC Fast Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of September 1, 2021 </strong>
    </p>
    <p>
      Electrify America, LLC (“Electrify America”) and Volvo Car Corporation (“Volvo”) are pleased to provide the Volvo
      DC Fast Charging Plan (“Promotion”) to drivers of new 2022 Volvo XC40 Recharge and C40 Recharge vehicles purchased
      from an authorized Volvo retailer. This Promotion provides 250 kWh of complimentary DC fast (CCS) and Level 2
      charging, at no additional cost and valid for 3 years, and a subsequent 12-month Electrify America Pass+
      membership. The Promotion starts from the date of vehicle purchase, of which is provided to Electrify America by
      your vehicle’s manufacturer. By clicking “Agree” below, you agree to participate in this Plan, subject to your
      compliance with all Electrify America terms applicable to the Plan (including the terms contained in this
      disclosure and the app’s Terms of Use, which together govern your use of the Plan).
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in the Volvo DC
      Fast Charging Plan, and acceptance of Terms of Use. Your mobile carrier’s normal messaging, data, and other rates
      and fees will apply to your use of the Electrify America app.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a program that provides
      250 kWh of DC fast (CCS) charging for your 2022 Volvo XC40 Recharge or C40 Recharge, and subsequent 12-month Pass+
      membership, compliments of Volvo.
    </p>
    <p>
      After signing up, you can redeem your complimentary 250 kWh of charging during your initiation of a charging
      session at any Electrify America charging station, exclusively by using the Electrify America app “Swipe to start”
      or NFC Pass feature with the appropriate plan selected. This complimentary charging may not be initiated using the
      interface screen or buttons on the charger. Once the 250 kWh is depleted, you can charge at a discounted rate
      through the 12-month Pass+ subscription.
    </p>
    <p>
      There is no additional fee or purchase required for your access to this promotion. After the 250 kWh credit is
      fully expended, you are responsible for all charging fees (including any applicable tax and idle fees) under your
      Pass+ subscription plan.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use the Volvo DC Fast Charging Plan for charging the Volvo XC40 Recharge or C40
      Recharge whose VIN you submitted when signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you or
      others.
    </p>
    <p>
      The complimentary 250 kWh charging time <strong>does not include</strong> any idle fees and their associated
      applicable taxes assessed at the charging station. You will be responsible for paying such fees through your
      account if you incur them during a charging session. When using the 12-month complimentary Pass+ subscription
      plan, you will be responsible for <strong>all</strong> fees you incur during the charging session, with the
      exception of the monthly membership fee.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the Volvo XC40 Recharge or
      C40 Recharge when charging at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the Volvo XC40 Recharge or C40
      Recharge at the charging station.
    </p>
    <p>
      Once the 250 kWh credit and 12-month Pass+ subscription term of this promotion have expired, you will not be able
      to reload or add more credit to this promotional plan. At that time, your account will automatically convert to a
      basic Electrify America “Pass” account, at no additional fee or charge to you (neither one-time nor recurring).
      You may, but are not obligated to, sign up for a different type of subscription via the Electrify America mobile
      app. Please refer to the information on the <strong>“Plan Details”</strong> section of the app to keep track of
      the amount of time remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the complimentary charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this program for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any remaining portion of the complimentary charging time.
    </p>
  </div>
</template>

<script>
export default {
  name: 'volvo-dc-fast-charging-disclosure',
  metaInfo: {
    title: 'Volvo Disclosure 2022 | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/volvo-dc-fast-charging-disclosure/' }],
  },
};
</script>
